/* import __COLOCATED_TEMPLATE__ from './required-attributes-panel.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { type ConversationRecord } from 'embercom/objects/inbox/types/conversation-record';
import type ConversationAttributeDescriptor from 'embercom/objects/inbox/conversation-attribute-descriptor';
import { emptyRequiredAttributesForConversation } from 'embercom/objects/inbox/conversation-attribute-descriptor';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ConversationAttributeSummary from 'embercom/objects/inbox/conversation-attribute-summary';
// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';

export interface RequiredAttributesPanelArgs {
  conversation: ConversationRecord;
  descriptors: ConversationAttributeDescriptor[];
  hidePanel: () => void;
  updateAttributesAndCloseConversation: (
    attributes: ConversationAttributeSummary[],
  ) => Promise<void>;
}

interface Signature {
  Args: RequiredAttributesPanelArgs;
}

export default class RequiredAttributesPanel extends Component<Signature> {
  @service declare inboxApi: InboxApi;
  @service declare intl: IntlService;

  @ref('panel') declare panel: HTMLDivElement;

  @tracked attributes = emptyRequiredAttributesForConversation(
    this.args.conversation,
    this.args.descriptors,
  );

  get canCloseConversation() {
    if (!this.attributes) {
      return false;
    }

    return this.attributes.every((attribute) => !attribute.isValueEmpty);
  }

  get warningText() {
    if (this.args.conversation.isCustomerTicket) {
      return this.intl.t(
        'inbox.conversations-table.required-attributes-panel.warning-customer-ticket',
      );
    }

    return this.intl.t('inbox.conversations-table.required-attributes-panel.warning');
  }

  get closeButtonText() {
    if (this.args.conversation.isCustomerTicket) {
      return this.intl.t(
        'inbox.conversations-table.required-attributes-panel.button-customer-ticket',
      );
    }

    return this.intl.t('inbox.conversations-table.required-attributes-panel.button');
  }

  @action async onUpdateAttribute(attribute: ConversationAttributeSummary) {
    if (!this.args.conversation.hasAttributeForDescriptor(attribute.descriptor.id)) {
      this.args.conversation.addAttribute(attribute);
    }

    return this.inboxApi.updateAttribute(this.args.conversation.id, attribute);
  }

  @action async closeConversation() {
    if (!this.attributes || !this.canCloseConversation) {
      return;
    }

    let updatedAttributes = this.attributes.filter((attribute) => attribute.isUpdated);
    await this.args.updateAttributesAndCloseConversation(updatedAttributes);

    // can't hide before calling updateAttributesAndCloseConversation
    // as that tears down the panel, which clears args
    this.args.hidePanel();
  }

  @action handleDocumentClick(ev: PointerEvent) {
    let ignoredElements = [
      this.panel,
      document.querySelector('.popover__content'),
    ].compact() as HTMLElement[];

    if (ignoredElements.any((element) => element.contains(ev.target as HTMLElement))) {
      return;
    }

    this.args.hidePanel();
  }

  @action handleEnter() {
    this.closeConversation();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::RequiredAttributesPanel': typeof RequiredAttributesPanel;
    'inbox2/required-attributes-panel': typeof RequiredAttributesPanel;
  }
}
