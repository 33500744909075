/* import __COLOCATED_TEMPLATE__ from './create-saved-reply-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { ProsemirrorComposerWrapper } from 'embercom/lib/inbox/saved-replies/prosemirror-composer-wrapper';
import { DEFAULT_MACRO_TYPES } from 'embercom/lib/inbox/constants';

export default class CreateSavedReplyModal extends Component {
  @service store;

  @service notificationsService;

  @service appService;

  @service router;

  @service intercomEventService;

  @tracked name = '';

  @tracked openInserters = [];

  @tracked prosemirrorComposerWrapper;

  @tracked savedReplyComposer;

  @tracked visibleToTeamIds = null;

  @tracked visibility = 'everyone';

  @tracked selectedTypes = this.initialTypes;

  editorInserters = [
    {
      name: 'article',
      icon: 'article',
      descriptor: 'Add an article',
      hasPopoverContentComponent: true,
    },
    {
      name: 'emoji',
      icon: 'lwr-happy',
      descriptor: 'Add an emoji',
      hasPopoverContentComponent: true,
    },
    {
      name: 'gif',
      icon: 'gif',
      descriptor: 'Add a GIF',
      hasPopoverContentComponent: true,
    },
    {
      name: 'image',
      icon: 'picture',
      descriptor: 'Add an image',
      hasPopoverContentComponent: false,
    },
    {
      name: 'attachment',
      icon: 'attachment',
      descriptor: 'Add an attachment',
      hasPopoverContentComponent: false,
    },
  ];

  constructor() {
    super(...arguments);
    let permissions = this.app.currentAdmin.currentAppPermissions;
    if (!permissions.can_manage_saved_replies) {
      this.visibility = 'owner';
    }
  }

  get app() {
    return this.appService.app;
  }

  get publicAPI() {
    return {
      app: this.app,
      actions: {
        registerOpenInserter: (...args) => this.registerOpenInserter(...args),
        clearOpenInserter: (...args) => this.clearOpenInserter(...args),
        paste: (...args) => this.paste(...args),
        uploadImage: (...args) => this.uploadImage(...args),
        uploadAttachment: (...args) => this.uploadAttachment(...args),
        insertBlock: (...args) => this.insertBlock(...args),
      },
    };
  }

  get focusTitle() {
    return this.args.focusTitle || false;
  }

  get initialTypes() {
    if (this.args.defaultMacroType) {
      return DEFAULT_MACRO_TYPES.filterBy('id', this.args.defaultMacroType);
    } else {
      return [];
    }
  }

  @action cancel() {
    this.args.onClose({ success: false });
  }

  @action openManageSavedReplies() {
    safeWindowOpen(this.router.urlFor('apps.app.settings.saved-replies'), '_blank');
  }

  @action
  setupProsemirrorComposerWrapper() {
    let macro = this.store.createRecord('composer-macro');
    this.prosemirrorComposerWrapper = new ProsemirrorComposerWrapper(this, macro);
    let blocks = this.args.blocks || [];
    this.prosemirrorComposerWrapper.load(blocks);
    this.savedReplyComposer = this.prosemirrorComposerWrapper;
  }

  registerOpenInserter(inserterName) {
    this.openInserters.pushObject(inserterName);
  }

  clearOpenInserter(inserterName) {
    if (!this.isDestroying) {
      this.openInserters.removeObject(inserterName);
    }
  }

  paste(text) {
    this.savedReplyComposer.send('paste', text, 'plain');
  }

  uploadImage(files) {
    this.savedReplyComposer.send('uploadImage', files);
  }

  uploadAttachment(files) {
    this.savedReplyComposer.send('uploadAttachment', files);
  }

  insertBlock(type, block) {
    this.savedReplyComposer.send('createBlock', type, block);
  }

  @action
  visibilityChanged(newVisibleToTeamIds, newVisibility) {
    this.visibleToTeamIds = newVisibleToTeamIds;
    this.visibility = newVisibility;
  }

  @action
  onTypesChange(types) {
    this.selectedTypes = DEFAULT_MACRO_TYPES.filter(({ id }) => types.includes(id));
  }

  @task({ restartable: true }) *saveSavedReply() {
    let actions = this.prosemirrorComposerWrapper.macro?.actionsPayload || [];

    let blocks = this.savedReplyComposer.getBlocks();
    let macroAttributes = {
      app_id: this.app.id,
      name: this.name,
      blocks,
      actions,
      visibleToTeamIds: this.visibleToTeamIds,
      visibility: this.visibility,
      types: this.selectedTypes.mapBy('id'),
    };

    let reply = this.store.createRecord('saved-reply', macroAttributes);

    if (!reply.availabilityValid) {
      this.notificationsService.notifyError('Select when this macro should be available');
      return;
    }

    if (!reply.isValid) {
      this.notificationsService.notifyError(reply.validationMessage);
      return;
    }

    try {
      yield reply.save();
      this.app.refreshSavedReplies();
    } catch (error) {
      console.error(error);

      if (error.jqXHR && error.jqXHR.status === 422) {
        if (error.jqXHR.responseJSON.errors.name[0] === 'has already been taken') {
          this.notificationsService.notifyError('Name has already been taken');
          return;
        }
      }

      this.notificationsService.notifyError('Failed to create macro, please try again.');

      return;
    }

    let actionTypes = reply.actions?.map((a) => a.type) || [];
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: 'macros.save',
      place: 'inbox',
      context: 'macros_inbox_modal',
      assign: actionTypes.any(
        (a) => a === 'assign-conversation' || a === 'assign-conversation-to-owner',
      ),
      close: actionTypes.any((a) => a === 'close-conversation'),
      snooze: actionTypes.any((a) => a === 'snooze-conversation'),
      tag: actionTypes.any((a) => a === 'add-tag-to-conversation'),
      priority: actionTypes.any((a) => a === 'change-conversation-priority'),
      cda: actionTypes.any((a) => a === 'set-conversation-data-attribute'),
      has_text: blocks.length > 0,
      visibility: reply.visibility,
    });

    this.args.onClose({ success: true });

    this.notificationsService.notifyConfirmation('Reply saved');
  }
}
