/* import __COLOCATED_TEMPLATE__ from './inbox-title.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { taskFor } from 'ember-concurrency-ts';
import moment from 'moment-timezone';
import type Router from '@ember/routing/router-service';

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

interface Args {
  titleTranslationKey: string;
  titleUntranslated?: string;
}

export default class InboxTitle extends Component<Signature> {
  @service declare session: Session;
  @tracked liveWorkflows: any = null;
  @service declare router: Router;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.getLiveWorkflows).perform();
  }

  @task({ drop: true })
  *getLiveWorkflows() {
    try {
      // @ts-ignore
      let liveWorkflowsSearch: any = yield this.contentSearch({
        object_types: [
          objectTypes.customBot,
          objectTypes.inboundCustomBot,
          objectTypes.buttonCustomBot,
          objectTypes.triggerableCustomBot,
        ],
        states: [states.live],
        match_behaviors: [],
        app_id: this.app.id,
        per_page: 2,
      });
      this.liveWorkflows = liveWorkflowsSearch;
    } catch (e) {
      console.error(e);
    }
  }

  async contentSearch(searchParams: any) {
    let response: any = await ajax({
      url: '/ember/content_service/contents/search',
      type: 'GET',
      data: searchParams,
    }).catch(() => {});

    return {
      totalCount: response.total_count,
    };
  }

  get hasNoLiveWorkflows() {
    return this.liveWorkflows?.totalCount < 1;
  }

  get isInUnassignedRoute() {
    return this.router.currentURL?.includes('unassigned');
  }

  get daysSinceWorkspaceCreated() {
    let dateCreated = this.session.workspace?.createdAt;
    let today = moment();
    let diff = today.diff(dateCreated, 'days');
    return diff < 30;
  }

  get app() {
    return this.session.workspace;
  }

  get isAnyChannelInstalled() {
    return this.app.isAnyChannelInstalled;
  }

  get hasAccessToWorkflows() {
    return this.app.hasAccessToWorkflows;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationList::InboxTitle': typeof InboxTitle;
    'inbox2/left-nav/conversation-list/inbox-title': typeof InboxTitle;
  }
}
