/* import __COLOCATED_TEMPLATE__ from './load-balanced-inboxes-empty.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';

interface Args {
  model: any;
}

export default class LoadBalancedInboxesEmpty extends Component<Args> {
  @service declare router: Router;
  @service declare appService: any;

  @action
  goToTeamsPage() {
    this.router.transitionTo('apps.app.settings.teams', this.appService.app.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::LoadBalancedInboxesEmpty': typeof LoadBalancedInboxesEmpty;
    'inbox/workload-management/load-balanced-inboxes-empty': typeof LoadBalancedInboxesEmpty;
  }
}
