/* import __COLOCATED_TEMPLATE__ from './attribute-sorting-section.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { SafeString } from 'handlebars';

export interface SortableAttribute {
  name: string;
  text: string;
  tooltipText: string | SafeString;
}

interface Args {
  workloadManagementSettings: any;
  attributeStatus: { isChanged: boolean };
  openInboxOrderDrawer: () => void;
  updateAttributeSorting: (sorting: string[], isChanged: boolean) => void;
  teams: any[];
  settings: any[];
}

const DEFAULT_SORTING_ORDER: string[] = ['inbox', 'priority', 'next_breach_time', 'waiting_since'];

export default class AttributeSortingSection extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked attributes: string[] =
    this.args.workloadManagementSettings.sortingCriteria || DEFAULT_SORTING_ORDER;

  @tracked saving = false;
  @tracked hoveredAttributeIndex = -1;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get allowedAttributes() {
    let attributes = ['inbox', 'priority', 'next_breach_time', 'waiting_since', 'started_at'];

    if (this.appService.app.canUseLbaTagMatch) {
      attributes.push('tag_match');
    }

    return attributes;
  }

  get sortingCriteriaList() {
    return [
      {
        name: 'priority',
        text: this.intl.t('settings.workload-management.conversation-sorting.priority'),
        tooltipText: this.intl.t(
          'settings.workload-management.conversation-sorting.priority-tooltip',
          { htmlSafe: true },
        ),
      },
      {
        name: 'next_breach_time',
        text: this.intl.t('settings.workload-management.conversation-sorting.sla'),
        tooltipText: this.intl.t('settings.workload-management.conversation-sorting.sla-tooltip', {
          htmlSafe: true,
        }),
      },
      {
        name: 'waiting_since',
        text: this.intl.t('settings.workload-management.conversation-sorting.waiting-since'),
        tooltipText: this.intl.t(
          'settings.workload-management.conversation-sorting.waiting-since-tooltip',
          { htmlSafe: true },
        ),
      },
      {
        name: 'inbox',
        text: this.intl.t('settings.workload-management.conversation-sorting.inbox-order'),
        tooltipText: new SafeString(
          this.intl.t('settings.workload-management.conversation-sorting.inbox-order-tooltip', {
            htmlSafe: true,
          }) + this.inboxNames,
        ),
      },
      {
        name: 'started_at',
        text: this.intl.t('settings.workload-management.conversation-sorting.started-at'),
        tooltipText: this.intl.t(
          'settings.workload-management.conversation-sorting.started-at-tooltip',
          { htmlSafe: true },
        ),
      },
      {
        name: 'tag_match',
        text: this.intl.t('settings.workload-management.conversation-sorting.tag-match'),
        tooltipText: this.intl.t(
          'settings.workload-management.conversation-sorting.tag-match-tooltip',
          { htmlSafe: true },
        ),
      },
    ];
  }

  get attributeLength() {
    return this.showAddAttributesButton
      ? this.sortedAttributes.length
      : this.sortedAttributes.length - 1;
  }

  get attributesToAdd() {
    return [
      {
        heading: this.intl.t(
          'settings.workload-management.conversation-sorting.sort-by-attributes',
        ),
        items: this.allowedAttributes
          .filter((attr) => {
            return !this.attributes.includes(attr);
          })
          .map((attr) => this.sortingCriteriaList.find((criteria) => criteria.name === attr)),
      },
    ];
  }

  get showAddAttributesButton() {
    return this.attributesToAdd[0].items.length;
  }

  get listUpdated() {
    let initialAttributes: string[] =
      this.args.workloadManagementSettings.sortingCriteria || DEFAULT_SORTING_ORDER;
    let a = !(
      this.attributes.length === initialAttributes.length &&
      this.attributes.every((val, index) => val === initialAttributes[index])
    );
    return a;
  }

  get sortedAttributes() {
    return this.attributes
      .filter((attr) => {
        return this.allowedAttributes.includes(attr);
      })
      .map((attr) => this.sortingCriteriaList.find((criteria) => criteria.name === attr));
  }

  get isOnlyOneAttributeLeft() {
    return this.attributes.length <= 1;
  }

  get inboxNames() {
    let allTeamSettings = this.args.teams.map((team) => {
      let setting = this.args.settings.find((s) => s.teamId === team.id);

      if (!setting || !team || setting.distributionMethod !== 'load_balanced') {
        return null;
      }

      return {
        team,
        setting,
      };
    });

    let orderedInboxNames = allTeamSettings
      .filter((teamSetting) => teamSetting !== null)
      .sort(function (a: any, b: any) {
        if (a.setting.order < b.setting.order) {
          return -1;
        }

        return 1;
      })
      .map((s: any, index: number) =>
        this.intl.t('settings.workload-management.conversation-sorting.inbox-name', {
          teamName: s.team.name,
          index: index + 1,
          htmlSafe: true,
        }),
      )
      .join('');

    return orderedInboxNames;
  }

  @action addAttribute(attribute: any) {
    this.attributes = [...this.attributes, attribute.name];
    this.attributesUpdated();
  }

  @action removeAttribute(attribute: string) {
    if (!this.isOnlyOneAttributeLeft) {
      this.attributes = this.attributes.filter((attr) => attr !== attribute);
      this.attributesUpdated();
    }
  }

  @action reorderAttributes(attributes: SortableAttribute[]) {
    this.attributes = attributes.map((criteria) => criteria.name);
    this.attributesUpdated();
    return attributes;
  }

  private attributesUpdated() {
    this.args.attributeStatus.isChanged = this.listUpdated;
    this.args.updateAttributeSorting(this.attributes, this.listUpdated);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::AttributeSortingSection': typeof AttributeSortingSection;
    'inbox/workload-management/attribute-sorting-section': typeof AttributeSortingSection;
  }
}
