/* import __COLOCATED_TEMPLATE__ from './new-button.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';
import type CommandKService from 'embercom/services/command-k';
import { ComposeNewAction } from 'embercom/objects/inbox/command-k/compose-new';
import type IntercomCallService from 'embercom/services/intercom-call-service';

interface Signature {
  Args: {
    onPopoverShow: () => void;
    onPopoverHide: () => void;
  };
}

export default class NewButton extends Component<Signature> {
  @service declare router: Router;
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare commandK: CommandKService;
  @service declare intercomCallService: IntercomCallService;
  @service declare intercomEventService: any;

  @action composeNewPane() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      place: 'inbox',
      object: 'new_message_button',
      section: 'inbox_sidenav_title',
      context: 'secondary_nav.new_button',
    });
    this.commandK.registerAndShow({
      actionID: 'compose-new',
      onSelect: (action: string) => {
        if (action === ComposeNewAction.newConversation) {
          this.startNewConversation();
        } else if (action === ComposeNewAction.newTicket) {
          setTimeout(() => {
            this.showCreateNewTicketPane();
          }, 200);
        } else if (action === ComposeNewAction.newPhoneCall) {
          this.intercomCallService.openDialler();
          this.intercomEventService.trackAnalyticsEvent({
            action: 'opened',
            object: 'dialler',
            place: 'create_button',
          });
        }
      },
    });
  }

  showCreateNewTicketPane() {
    this.commandK.registerAndShow({
      actionID: 'create-linked-ticket',
      context: {
        newTicket: true,
      },
      onSelect: (action: any) => {
        this.createNewTicket(action.id);
      },
    });
  }

  @action startNewConversation() {
    this.router.transitionTo('inbox.workspace.inbox.new-conversation', {
      queryParams: {
        ticketTypeId: undefined,
        forwardedPartId: undefined,
      },
    });
  }

  @action createNewTicket(ticketTypeId: number) {
    this.router.transitionTo('inbox.workspace.inbox.new-conversation', {
      queryParams: {
        ticketTypeId,
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::NewButton': typeof NewButton;
    'inbox2/left-nav/new-button': typeof NewButton;
  }
}
