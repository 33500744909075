/* import __COLOCATED_TEMPLATE__ from './assignment-limit-setting.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  capacity: any;
  assignmentLimitUpdate: { isChanged: boolean };
  updateAssignmentLimit?: (limit: number) => void;
}

export default class AssignmentLimitSetting extends Component<Args> {
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked tempCapacityLimit: any = this.args.capacity.capacityLimit;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get validCapacityLimit() {
    return this.tempCapacityLimit && this.tempCapacityLimit > 0;
  }

  get limitUpdated() {
    let isSame =
      !isNaN(this.tempCapacityLimit) &&
      parseInt(this.args.capacity.capacityLimit, 10) === parseInt(this.tempCapacityLimit, 10);
    return !isSame;
  }

  @action
  updateTempCapacityLimit() {
    this.args.assignmentLimitUpdate.isChanged = this.limitUpdated;
    if (this.args.updateAssignmentLimit) {
      this.args.updateAssignmentLimit(this.tempCapacityLimit);
    }
  }

  @action
  async save() {
    try {
      this.args.capacity.capacityLimit = this.tempCapacityLimit;
      await this.args.capacity.save();
      this.updateTempCapacityLimit();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.workload-management.assignment-limit.success'),
      );
    } catch (err) {
      if (!err) {
        return;
      }
      let notificationText;
      let responseJSON = err.jqXHR.responseJSON;
      if (responseJSON && responseJSON.errors[0]) {
        notificationText = responseJSON.errors[0];
      } else {
        notificationText = this.intl.t('settings.workload-management.assignment-limit.fail');
      }
      this.notificationsService.notifyError(notificationText);
    }
  }

  get showTicketsExcludedNote() {
    if (this.appService.app.canUseTicketsAssignmentSwitch) {
      return false;
    }

    return this.appService.app.excludeTicketsBalancedAssignment;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox::WorkloadManagement::AssignmentLimitSetting': typeof AssignmentLimitSetting;
    'inbox/workload-management/assignment-limit-setting': typeof AssignmentLimitSetting;
  }
}
