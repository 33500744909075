/* import __COLOCATED_TEMPLATE__ from './phone-call-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type TwilioService from 'embercom/services/twilio-service';
import type IntlService from 'embercom/services/intl';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import Admin from 'embercom/models/admin';
import { isEmpty } from '@ember/utils';

interface Args {}

export default class PhoneCallModal extends Component<Args> {
  @service declare twilioService: TwilioService;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  // eslint-disable-next-line @intercom/intercom/service-allow-list
  @service store: any;

  @tracked isMuted = false;
  @tracked isOnHold = false;

  @action hangUp() {
    if (this.twilioService.activeCall && this.twilioService.incomingCall) {
      this.twilioService.activeCall.hangedUpByAdmin = true;
      return this.twilioService.teammateHangUp();
    }
    return this.twilioService.activeCall?.hangUp();
  }

  @action pressDigit(digit: string) {
    this.twilioService.activeCall?.pressDigit(digit);
  }

  @action toggleOnHold() {
    if (!this.twilioService.disableHold) {
      this.twilioService?.toggleOnHold(this.isOnHold);
      this.isOnHold = !this.isOnHold;
    }
  }

  @action mute() {
    if (this.twilioService.activeCall) {
      this.isMuted = this.twilioService.activeCall.mute();
    }
  }

  @task({ drop: true })
  *transferTask(newAssigneeId: number): TaskGenerator<void> {
    let assignee = this.getAssigneeById(newAssigneeId);
    if (isEmpty(assignee)) {
      throw new Error('Assignee is not defined');
    }
    if (assignee?.isTeam) {
      yield this.twilioService.transferToTeam(newAssigneeId);
    } else {
      this.twilioService.newTeammateName = assignee?.name;
      this.isOnHold = true;
      try {
        yield this.twilioService.transferToAdmin(newAssigneeId);
      } catch (e) {
        if (e.jqXHR.status === 400) {
          this.notificationsService.notifyError(
            this.intl.t('calling.errors.calling-transfer-admin-unavialable'),
          );
        } else {
          this.notificationsService.notifyError(
            this.intl.t('calling.errors.calling-transfer-failue'),
          );
        }
        this.isOnHold = false;
      }
    }
  }

  @task({ drop: true })
  *transferToExternalNumberTask(externalNumber: string): TaskGenerator<void> {
    this.twilioService.externalNumber = externalNumber;
    this.isOnHold = true;
    try {
      yield this.twilioService.transferToExternalNumber(externalNumber);
    } catch (e) {
      this.notificationsService.notifyError(this.intl.t('calling.errors.calling-transfer-failue'));
      this.isOnHold = false;
    }
  }

  getAssigneeById(assigneeId: number) {
    let assignee = Admin.peekAndMaybeLoad(this.store, String(assigneeId));
    return assignee;
  }

  @action
  toggleRecording() {
    this.twilioService.toggleRecording();
  }

  get state() {
    return this.twilioService.callState || 'connecting';
  }

  get userSummary() {
    return this.twilioService.userSummary;
  }

  get isTimerVisible() {
    return this.state === 'accept';
  }

  get renderOnHold() {
    return this.state === 'accept';
  }

  get renderTransfer() {
    return (
      this.state === 'accept' &&
      (this.twilioService.incomingCall !== null ||
        this.session.workspace.isFeatureEnabled('team-phone-outbound-transfers'))
    );
  }

  get startTime() {
    return this.twilioService.activeCall?.startTime;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneCallModal': typeof PhoneCallModal;
    'inbox2/phone-call-modal': typeof PhoneCallModal;
  }
}
