/* import __COLOCATED_TEMPLATE__ from './phone-dialler-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import { action } from '@ember/object';
import { restartableTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

import Component from '@glimmer/component';
import { type TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type TwilioService from 'embercom/services/twilio-service';
import type IntlService from 'embercom/services/intl';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import type InboxState from 'embercom/services/inbox-state';
import type RouterService from '@ember/routing/router-service';
import type InboxSearchSuggestionsService from 'embercom/services/inbox-search-suggestions-service';
import { taskFor } from 'ember-concurrency-ts';
import TeamSummary from 'embercom/objects/inbox/team-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import type InboxApi from 'embercom/services/inbox-api';
import type PhoneNumber from 'embercom/models/inbox/phone-number';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { type NewConversationWireFormat } from 'embercom/lib/inbox2/types';
import PhoneCountryCodes from 'embercom/lib/phone-codes';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type Inbox2AssigneeSearch from 'embercom/services/inbox2-assignee-search';
import type SearchableDocument from 'embercom/objects/inbox/searchable-document';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import { ChannelAvailabilityCode } from 'embercom/services/inbox2-teammate-activity-service';

interface Args {
  phoneNumber?: string;
}

export default class PhoneDiallerModal extends Component<Args> {
  @service declare twilioService: TwilioService;
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomCallService: IntercomCallService;
  @service declare inboxState: InboxState;
  @service declare router: RouterService;
  @service declare inboxSearchSuggestionsService: InboxSearchSuggestionsService;
  @service declare inboxApi: InboxApi;
  @service declare intercomEventService: any;
  @service declare inbox2AssigneeSearch: Inbox2AssigneeSearch;

  @tracked toPhoneNumber = this.args.phoneNumber || '';
  @tracked phoneNumbers: any[] = [];
  @tracked phoneNumberGroupList?: any;
  @tracked selectedPhoneNumber?: string;
  @tracked selectedCountry?: string;
  @tracked countryCode = '';
  @tracked selectedMode = 'keypad';
  @tracked userSearchQuery = '';
  @tracked userInitialList: UserSummary[] = [];
  @tracked userSearchResults: UserSummary[] = [];
  @tracked isLoadingUserSearchResults = false;
  @tracked isActivelyCalling = false;
  @tracked isActivelyCallingId?: string | number;
  @tracked listOfTransferOptions: any;

  phoneNumbersMap: { [key: string]: string } = {};

  constructor(owner: unknown, args: never) {
    super(owner, args);
    if (this.isCallTransferMode) {
      this.selectedMode = 'user_search';
      taskFor(this.refreshTransferOptions).perform();
    }
    this.getWorkspacePhoneNumbers();
    this.phoneNumberChanged();
    taskFor(this.onQueryChanged).perform(this.userSearchQuery);
  }

  async getWorkspacePhoneNumbers() {
    this.phoneNumbers = this.intercomCallService.workspacePhoneNumbers.map(
      (phoneNumber: PhoneNumber) => ({
        text: phoneNumber.name,
        value: phoneNumber.phoneNumber,
        component: 'inbox2/phone-number-option',
        componentAttrs: {
          name: phoneNumber.name,
          phoneNumber: phoneNumber.phoneNumber,
        },
      }),
    );
    this.phoneNumberGroupList = [{ items: this.phoneNumbers }];
    this.selectedPhoneNumber = this.phoneNumbers[0]?.value;
    if (!this.toPhoneNumber && this.intercomCallService.workspacePhoneNumbers.length > 0) {
      this.selectedCountry =
        this.intercomCallService.workspacePhoneNumbers[0].countryIsoCode.toLowerCase();
      this.setPhoneNumberPrefix(this.selectedCountry);
    }
    this.phoneNumbers.forEach((phoneNumber) => {
      this.phoneNumbersMap[phoneNumber.value] = phoneNumber.text;
    });
  }

  get countries() {
    return Object.values(PhoneCountryCodes).map((phoneCodeObject: any) => ({
      text: this.intl.t(phoneCodeObject.nameTranslationKey),
      value: phoneCodeObject.code,
      component: 'inbox2/country-code-option',
      componentAttrs: {
        name: this.intl.t(phoneCodeObject.nameTranslationKey),
        code: phoneCodeObject.dial_code,
        flagUrl: phoneCodeObject.asset_url,
      },
    }));
  }

  @action
  setPhoneNumberPrefix(country: string) {
    this.selectedCountry = country;
    this.toPhoneNumber = this.toPhoneNumber.replace(this.countryCode, '').replace(' ', '');
    this.countryCode = (PhoneCountryCodes as any)[this.selectedCountry].dial_code;
    this.toPhoneNumber = `${this.countryCode} ${this.toPhoneNumber}`;
  }

  get selectedCountryFlagAssetUrl() {
    return assetUrl(`/assets/svgs/flags/${this.selectedCountry}.svg`);
  }

  async createConversation(contactId: string) {
    let user = await this.inboxSearchSuggestionsService.loadUserFromId(contactId);
    if (!user) {
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.find-or-create-contact-error'),
      );
      return;
    }

    if (!this.session.isTeammateLoaded) {
      this.session.getTeammate(this.session.workspace.id);
    }

    let data: NewConversationWireFormat = {
      app_id: this.session.workspace.id,
      admin_assignee_id: this.session.teammate.id,
      team_assignee_id: TeamSummary.unassigned.id,
      sender_id: this.session.teammate.id,
      blocks: [],
      type: objectTypes.call,
      user_ids: [user.id],
    };

    return await this.inboxState.createNewConversation(data, [], false);
  }

  setCallingState(id?: string | number) {
    this.isActivelyCalling = true;
    this.isActivelyCallingId = id;
  }

  resetCallingState() {
    this.isActivelyCalling = false;
    this.isActivelyCallingId = undefined;
  }

  @action
  async callUser(user: UserSummary) {
    this.setCallingState(user.id);

    let phoneNumber = user.phone;
    if (!phoneNumber) {
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.validate-phone-number-error'),
      );
      this.resetCallingState();
      return;
    }
    await this.callNumber(phoneNumber);

    this.resetCallingState();
  }

  @action
  async dialNumber() {
    this.setCallingState();

    let validatedPhoneNumber = await this.intercomCallService.getValidatedPhoneNumber(
      this.toPhoneNumber,
      this.selectedCountry!,
    );
    if (!validatedPhoneNumber) {
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.validate-phone-number-error'),
      );
      this.resetCallingState();
      return;
    }

    await this.callNumber(validatedPhoneNumber);
    this.resetCallingState();
  }

  async callNumber(phoneNumber: string) {
    if (this.isCallTransferMode) {
      this.transferCallExternally(phoneNumber);
      return;
    }

    let contact = await this.intercomCallService.findOrCreateContact(phoneNumber);

    let preferredConversationId = await this.intercomCallService.contactsActiveCallConversation(
      contact.id,
    );
    if (preferredConversationId) {
      this.router.transitionTo(
        'inbox.workspace.inbox.conversation.conversation',
        preferredConversationId,
      );
      this.intercomCallService.closeDialler();
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.contact-already-engaged'),
      );
      return;
    }

    let conversation = await this.createConversation(contact.id);
    if (!conversation) {
      this.notificationsService.notifyError(
        this.intl.t('calling.dialler-modal.create-conversation-error'),
      );
      return;
    }

    this.router.transitionTo('inbox.workspace.inbox.conversation.conversation', conversation.id);
    let conversationObj = await this.inboxApi.fetchConversation(conversation.id);
    await this.twilioService.callNumber(
      phoneNumber,
      conversationObj,
      contact,
      this.selectedPhoneNumber,
    );
    this.intercomCallService.closeDialler();
    let callPlacedInMode = this.selectedMode === 'keypad' ? 'calling' : 'user_search';
    this.intercomEventService.trackAnalyticsEvent({
      action: 'called',
      object: 'dialler_modal',
      place: callPlacedInMode,
    });
  }

  @action
  close() {
    this.intercomCallService.closeDialler();
  }

  get selectedPhoneNumberText() {
    if (!this.selectedPhoneNumber) {
      return '';
    }

    return this.phoneNumbersMap[this.selectedPhoneNumber] || this.selectedPhoneNumber;
  }

  @action
  inputDigit(keyCode: string) {
    if (keyCode === '+') {
      this.toPhoneNumber = this.toPhoneNumber.slice(0, -2);
    }
    this.toPhoneNumber += keyCode;
    this.phoneNumberChanged();
  }

  @action
  phoneNumberChanged(event?: Event) {
    let phoneNumber = (event?.target as HTMLInputElement)?.value || this.toPhoneNumber;
    Object.values(PhoneCountryCodes).forEach((phoneCodeObject: any) => {
      if (phoneNumber.startsWith(phoneCodeObject.dial_code)) {
        this.selectedCountry = phoneCodeObject.code;
        this.countryCode = phoneCodeObject.dial_code;
      }
    });
  }

  @action
  setSelectedPhoneNumber(value: string) {
    if (!this.toPhoneNumber || this.toPhoneNumber.trim() === this.countryCode) {
      this.selectedCountry = this.intercomCallService.workspacePhoneNumbers
        .findBy('phoneNumber', value)
        ?.countryIsoCode.toLowerCase();
      this.setPhoneNumberPrefix(this.selectedCountry!);
    }
    this.selectedPhoneNumber = value;
  }

  @restartableTask *onQueryChanged(query: string): TaskGenerator<void> {
    this.userSearchQuery = query;
    this.userSearchResults = [];
    if (!query && this.userInitialList.length > 0) {
      this.userSearchResults = this.userInitialList;
      this.isLoadingUserSearchResults = false;
      return;
    }
    this.isLoadingUserSearchResults = true;

    let users: UserSummary[] =
      yield this.inboxSearchSuggestionsService.getUserSuggestionsForDialler(
        this.userSearchQuery,
        10,
        'contains',
      );
    if (this.userInitialList.length === 0) {
      this.userInitialList = users;
    }
    this.userSearchResults = users;

    if (users.length === 0) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'user_search',
        object: 'dialler_modal',
        place: 'user_search',
        context: 'no_results',
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'user_search',
        object: 'dialler_modal',
        place: 'user_search',
        context: 'results_available',
      });
    }
    this.isLoadingUserSearchResults = false;
  }

  @action switchMode(mode: string) {
    this.selectedMode = mode;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'ui_mode_switched',
      object: 'dialler_modal',
      place: 'dialler_modal',
      context: mode,
    });
  }

  get isLoading() {
    if (this.isLoadingUserSearchResults) {
      return true;
    }

    if (this.isCallTransferMode && this.isRefreshingTransferOptions) {
      return true;
    }

    return false;
  }

  get isCallTransferMode() {
    return this.twilioService.isActiveCall;
  }

  get transferTeams(): Array<TeamSummary> {
    let teamSearchableDocuments = this.inbox2AssigneeSearch.searchableTeams;
    let teamSummaryObjects = teamSearchableDocuments.map(
      (teamSearchableDocument: SearchableDocument) => teamSearchableDocument.data as TeamSummary,
    );
    teamSummaryObjects = teamSummaryObjects.filter(
      (team) =>
        !team.isUnassignedAssignee &&
        team.name.toLowerCase().includes(this.userSearchQuery.toLowerCase()),
    );

    return teamSummaryObjects;
  }

  @restartableTask *refreshTransferOptions(): TaskGenerator<void> {
    yield this.inbox2AssigneeSearch.loadAllAdminsAndTeams();
  }

  get adminsForApp(): Array<AdminSummary> {
    let allAdmins = this.inbox2AssigneeSearch.searchableAdmins;
    let adminSummaryObjects = allAdmins.map(
      (adminSearchableDocument: SearchableDocument) => adminSearchableDocument.data as AdminSummary,
    );
    adminSummaryObjects = adminSummaryObjects.filter(
      (admin) =>
        !admin.isUnassignedAssignee &&
        admin.restrictedContexts?.length === 0 &&
        admin.adminStatus?.awayModeEnabled === false &&
        (admin.adminStatus?.channelAvailability === ChannelAvailabilityCode.Both ||
          admin.adminStatus?.channelAvailability === ChannelAvailabilityCode.Phone) &&
        admin.name.toLowerCase().includes(this.userSearchQuery.toLowerCase()),
    );
    return adminSummaryObjects;
  }

  get isRefreshingTransferOptions() {
    return taskFor(this.refreshTransferOptions).isRunning;
  }

  @action transferCallToAdmin(admin: AdminSummary) {
    this.transferCallInternally(admin.id);
  }

  @action transferCallToTeam(team: TeamSummary) {
    this.transferCallInternally(team.id);
  }

  transferCallInternally(id: number) {
    this.setCallingState(id);
    this.intercomCallService.transferCallInternally(id);
    this.close();
    this.resetCallingState();
  }

  @action transferCallExternally(phoneNumber: string) {
    this.intercomCallService.transferCallExternally(phoneNumber);
    this.close();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::PhoneDiallerModal': typeof PhoneDiallerModal;
    'inbox2/phone-dialler-modal': typeof PhoneDiallerModal;
  }
}
