/* import __COLOCATED_TEMPLATE__ from './upload-pill.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Inbox2ImagePreviewService from 'embercom/services/inbox2-image-preview-service';
import { type Upload } from 'embercom/components/inbox2/ticket-attribute/file';

interface Args {}

interface Signature {
  Args: Args;
}

export default class UploadPill extends Component<Signature> {
  @service declare inbox2ImagePreviewService: Inbox2ImagePreviewService;

  @action handlePreviewImageClick(file: Upload) {
    if (file.contentType.startsWith('image/') && file.url) {
      this.inbox2ImagePreviewService.onPreviewImageClick(file.url);
    } else {
      safeWindowOpen(file.url);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::TicketAttribute::FileAttribute::UploadPill': typeof UploadPill;
    'inbox2/ticket-attribute/file-attribute/upload-pill': typeof UploadPill;
  }
}
