/* import __COLOCATED_TEMPLATE__ from './conversation-list.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { restartableTask, task } from 'ember-concurrency-decorators';
import { modifier } from 'ember-modifier';
import type InboxState from 'embercom/services/inbox-state';
import { InboxEvents } from 'embercom/services/inbox-state';
import ConversationListInboxResource from 'embercom/components/inbox2/conversation-list-inbox-resource';
import {
  InboxMentionsStatus,
  InboxSortOption,
  type InboxStateOption,
  isConversationStateEqual,
} from 'embercom/models/data/inbox/inbox-filters';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import type Session from 'embercom/services/session';
import { timeout, didCancel } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type InboxApi from 'embercom/services/inbox-api';
import type CommandK from 'embercom/services/command-k';
import Mentions from 'embercom/objects/inbox/inboxes/mentions';
import { next, scheduleOnce } from '@ember/runloop';
// @ts-ignore
import { dedupeTracked, trackedReset } from 'tracked-toolbox';
import type ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type Conversation from 'embercom/objects/inbox/conversation';
import type Nexus from 'embercom/services/nexus';
import { NexusStatusEvents } from 'embercom/services/nexus';
import type RouterService from '@ember/routing/router-service';
import { type HotkeysMap } from 'embercom/services/inbox-hotkeys';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type Transition from '@ember/routing/transition';
import isActiveElementAnInput from 'embercom/helpers/is-active-element-an-input';
import type PullConversationService from 'embercom/services/pull-conversation-service';
import type FinQuestionAnswers from 'embercom/services/fin-question-answers';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';
import type ConversationResolveAndCloseService from 'embercom/services/conversation-resolve-and-close-service';

interface Args {}

interface Signature {
  Args: Args;
}

export default class ConversationListComponent extends Component<Signature> {
  @service declare inboxState: InboxState;
  @service declare inboxApi: InboxApi;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare commandK: CommandK;
  @service declare session: Session;
  @service declare router: RouterService;
  @service declare nexus: Nexus;
  @service declare inboxHotkeys: InboxHotkeys;
  @service declare pullConversationService: PullConversationService;
  @service declare finQuestionAnswers: FinQuestionAnswers;
  @service declare lbaMetricsService: LbaMetricsService;
  @service declare conversationResolveAndCloseService: ConversationResolveAndCloseService;

  @trackedReset('inboxState.activeInbox') selectedSortOption: InboxSortOption = this.defaultSort;
  @trackedReset('inboxState.activeInbox') selectedStateOption: InboxStateOption = this.defaultState;
  @dedupeTracked selectedMentionsStatus: InboxMentionsStatus = InboxMentionsStatus.All;

  items: Array<HTMLAnchorElement> = [];
  _currentItem: HTMLAnchorElement | null = null;

  @tracked conversationListIsInFocus = true;
  @tracked selectedBulkAssignConversations: ConversationSummary['id'][] = [];

  readonly conversationRoute = 'inbox.workspace.inbox.inbox.conversation.conversation';

  readonly hotkeys: HotkeysMap;
  readonly handleNotEditorHotkey;
  readonly handleFocusWithin;

  conversationList = ConversationListInboxResource.from(this, () => ({
    inbox: this.inboxState.activeInbox,
    selectedSortOption: this.selectedSortOption,
    selectedStateOption: this.selectedStateOption,
    selectedMentionsStatus: this.selectedMentionsStatus,
    selectedConversations: this.inboxState.selectedConversations.conversationObjects.length,
    onResetList: this.onResetConversationList,
    onConversationStateChanged: this.onConversationStateChanged,
  }));

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.inboxState.on(InboxEvents.ConversationRead, this.handleConversationReadEvent);
    this.inboxState.on(InboxEvents.ConversationUnread, this.handleConversationUnreadEvent);
    this.inboxState.on(InboxEvents.ConversationRemoved, this, this.handleRemoveConversation);

    this.nexus.on(NexusStatusEvents.Reconnected, this.reload);
    this.hotkeys = this.inboxHotkeys.hotkeysMap;
    this.handleNotEditorHotkey = this.inboxHotkeys.handleNotEditorHotkey;
    this.handleFocusWithin = this.inboxHotkeys.handleFocusWithin;
  }

  willDestroy() {
    super.willDestroy();

    this.inboxState.off(InboxEvents.ConversationRead, this.handleConversationReadEvent);
    this.inboxState.off(InboxEvents.ConversationUnread, this.handleConversationUnreadEvent);
    this.inboxState.off(InboxEvents.ConversationRemoved, this, this.handleRemoveConversation);

    this.nexus.off(NexusStatusEvents.Reconnected, this.reload);

    this.items = [];
    this._currentItem = null;
  }

  handleRemoveConversation(conversation: Conversation) {
    if (this.conversationList.conversations.length > 1) {
      this.goToNextConversation();
      this.conversationList.removeConversation(conversation);
    } else {
      this.conversationList.removeConversation(conversation);
      this.router.transitionTo('inbox.workspace.inbox.inbox.index', {
        queryParams: { skipRedirection: true },
      });
    }
  }

  /**
   * Navigation
   */

  keyboardNavigable = modifier((element: HTMLAnchorElement) => {
    let inHandler = () => {
      next(() => {
        this.conversationListIsInFocus = true;
      });
    };

    let outHandler = () => {
      next(() => {
        this.conversationListIsInFocus = false;
      });
    };

    element.addEventListener('focusin', inHandler);
    element.addEventListener('focusout', outHandler);

    return function () {
      element.removeEventListener('focusin', inHandler);
      element.removeEventListener('focusout', outHandler);
    };
  });

  registerKeyboardNavigableItem = modifier((element: HTMLAnchorElement) => {
    let id = this.inboxState.activeConversationId;

    if (element.dataset.conversationId === `${id}`) {
      scheduleOnce('afterRender', this, this.focusElement, element);

      element.tabIndex = 0;
      this.currentItem = element;
    }

    this.items = [...this.items, element];

    return () => {
      let index = this.items.indexOf(element);
      this.items.splice(index, 1);
    };
  });

  resetScrollPosition = modifier((element, [conversationList]: [ConversationListInboxResource]) => {
    if (conversationList.conversations.length === 0) {
      element.scrollTop = 0;
    }
  });

  get conversationsWithAccess() {
    return this.conversationList.conversations.filter((conversation) => !conversation.redacted);
  }

  get isBulkSelectionReplyable(): boolean {
    return this.inboxState.selectedConversations.conversationObjects.every(
      (conversation) => conversation.isReplyable,
    );
  }

  @action handleShiftArrowUp() {
    this.handleMoveUp();
    this.handleKeyX();
  }

  @action handleShiftArrowDown() {
    this.handleMoveDown();
    this.handleKeyX();
  }

  @action handleMoveUp() {
    if (!isActiveElementAnInput()) {
      this.navigateConversationList(-1, !this.inboxState.hasSelectedConversations);
    }
  }

  @action handleMoveDown() {
    if (!isActiveElementAnInput()) {
      this.navigateConversationList(1, !this.inboxState.hasSelectedConversations);
    }
  }

  @action handleKeyK() {
    this.handleMoveUp();
  }

  @action handleKeyJ() {
    this.handleMoveDown();
  }

  @action handleArrowUp(event: KeyboardEvent) {
    if (event.shiftKey) {
      return;
    }

    this.handleMoveUp();
  }

  @action handleArrowDown(event: KeyboardEvent) {
    if (event.shiftKey) {
      return;
    }

    this.handleMoveDown();
  }

  @action handleKeyX() {
    let conversationToToggle;
    let keyboardFocusIsOnCurrentItem = document.activeElement === this.currentItem;
    let noFocusedOrNoSelectedConversation =
      document.activeElement !== this.currentItem || !this.inboxState.hasSelectedConversations;

    if (keyboardFocusIsOnCurrentItem) {
      let conversationToToggle =
        this.currentItemId !== undefined
          ? this.conversationList.conversations.findBy('id', this.currentItemId)
          : undefined;

      if (!conversationToToggle) {
        return;
      }

      return this.toggleSelection({ conversation: conversationToToggle });
    }

    if (noFocusedOrNoSelectedConversation) {
      conversationToToggle = this.conversationList.conversations.findBy(
        'id',
        this.inboxState.activeConversationId,
      );

      if (!conversationToToggle) {
        return;
      }

      this.setCurrentItem(conversationToToggle.id);
      return this.toggleSelection({ conversation: conversationToToggle });
    }
  }

  navigateConversationList(increment: number, shouldFetch = true) {
    let currentItem = this.currentItem;
    let currentIndex = this.items.indexOf(currentItem);
    let nextOrPreviousIndex = currentIndex + increment;

    if (nextOrPreviousIndex < 0 || nextOrPreviousIndex >= this.items.length) {
      return;
    }

    currentItem.tabIndex = -1;

    let nextOrPreviousItem = this.items[nextOrPreviousIndex];
    nextOrPreviousItem.tabIndex = 0;
    nextOrPreviousItem.focus();

    this.currentItem = nextOrPreviousItem;

    if (shouldFetch) {
      taskFor(this.debounce).perform(() => {
        let id = nextOrPreviousItem.dataset.conversationId;
        if (!id) {
          return;
        }

        this.router.transitionTo('inbox.workspace.inbox.inbox.conversation.conversation', id);
      });
    }
  }

  @restartableTask *debounce(fn: Function) {
    yield timeout(200);

    fn();
  }

  get currentItem(): HTMLAnchorElement {
    if (!this._currentItem) {
      let [first] = this.items;

      return first;
    }

    return this._currentItem;
  }

  set currentItem(item: HTMLAnchorElement) {
    this._currentItem = item;
  }

  get currentItemId() {
    let id = this.currentItem?.dataset.conversationId;
    return id ? Number(id) : undefined;
  }

  get showPullConversationButton() {
    return (
      this.pullConversationService.isPullConversationEnabled &&
      this.inboxState.activeInbox?.type === InboxType.Admin &&
      this.inboxState.activeInbox?.id === this.session.teammate.id.toString()
    );
  }

  private focusElement(element: HTMLElement) {
    if (!isActiveElementAnInput() && !this.commandK.isVisible) {
      element.focus();
    }
  }

  @action goToNextConversation() {
    let currentConversationIndex = this.conversationList.conversations.findIndex(
      (conversation: ConversationSummary) =>
        conversation.id === this.inboxState.activeConversationId,
    );

    let isLastConversation =
      currentConversationIndex === this.conversationList.conversations.length - 1;

    let nextConversation: ConversationSummary | undefined = undefined;
    if (isLastConversation) {
      // If closing the last conversation in the list, go back to the one right before it.
      nextConversation = this.conversationList.conversations[currentConversationIndex - 1];
    } else if (currentConversationIndex > -1) {
      nextConversation = this.conversationList.conversations[currentConversationIndex + 1];
    }

    if (nextConversation) {
      this.router.transitionTo(this.conversationRoute, nextConversation);
    }
  }

  /**
   * Headers and Filtering
   */

  get placeholderCount(): number {
    return this.conversationList.countAdditionalConversationsBeingFetched;
  }

  get showViewSwitcher(): boolean {
    return !this.inboxState.isConversationListHidden && !this.session.showLightInbox;
  }

  get titleTranslationKey() {
    return this.inboxState.activeInbox?.translationKey;
  }

  get titleUntranslated() {
    return this.inboxState.activeInbox?.name;
  }

  get defaultSort() {
    return this.inboxState.getDefaultSort(this.inboxState.activeInbox);
  }

  get defaultState() {
    return this.inboxState.getDefaultState(this.inboxState.activeInbox);
  }

  get isMentionsInbox() {
    return this.inboxState.activeInbox instanceof Mentions;
  }

  get availableSortOptions() {
    return [
      InboxSortOption.Oldest,
      InboxSortOption.Newest,
      InboxSortOption.StartedFirst,
      InboxSortOption.StartedLast,
      InboxSortOption.WaitingLongest,
      InboxSortOption.NextSlaTarget,
      InboxSortOption.PriorityNewest,
    ];
  }

  @action
  onStateChange(state: InboxStateOption) {
    if (state !== this.selectedStateOption) {
      this.selectedStateOption = state;
    }
    this.inboxState.setDefaultState(this.inboxState.activeInbox!, state);

    this.selectedSortOption = this.inboxState.getDefaultSort(this.inboxState.activeInbox);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      object: 'conversation_list',
      section: 'conversation_thread',
      state,
      inbox_type: this.inboxState.activeInbox?.type,
    });
  }

  @action
  onMentionsStatusChange(status: InboxMentionsStatus) {
    this.selectedMentionsStatus = status;
  }

  @action
  onSortChange(sort: InboxSortOption) {
    if (sort !== this.selectedSortOption) {
      this.selectedSortOption = sort;
    }
    this.inboxState.setDefaultSort(this.inboxState.activeInbox!, sort, this.selectedStateOption);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered',
      object: 'conversation_list',
      section: 'conversation_thread',
      sorted_by: sort,
      inbox_type: this.inboxState.activeInbox?.type,
    });
  }

  /**
   * Bulk actions
   */

  @action showBulkEditModal() {
    this.inboxState.isShowingBulkEditModal = true;
  }

  get canReplyToSelectedConversations(): boolean {
    if (this.session.skipCanReplyToInboundCheck) {
      return true;
    }
    return this.inboxState.selectedConversations.conversationObjects.every((conversation) => {
      return !conversation.isInboundConversation;
    });
  }

  @task *closeSelectedConversations() {
    yield this.conversationResolveAndCloseService.startResolveAndCloseProcess(
      this.inboxState.selectedConversations.conversationObjects,
    );
  }

  @task *reopenSelectedConversations() {
    try {
      yield this.inboxState.openConversations(this.inboxState.selectedConversations.ids);
    } catch {
      this.notificationsService.notifyError(this.intl.t('inbox.bulk-edit.errors.reopen'));
    }
  }

  @task *assignSelectedConversations(assignee: AdminWithPermissions | TeamSummary) {
    let selectedConversations = this.inboxState.selectedConversations
      .conversationObjects as ConversationSummary[];
    this.selectedBulkAssignConversations = selectedConversations.map(
      (conversation) => conversation.id,
    );
    yield this.inboxState.assignConversations(selectedConversations, assignee);
    this.selectedBulkAssignConversations = [];
    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
      LbaTriggerEvent.BULK_ASSIGN,
    );
  }

  @task *executeBulkActions(actions: MacroAction[]) {
    let ids = this.inboxState.selectedConversations.ids;

    try {
      this.inboxState.isShowingBulkEditModal = false;
      this.inboxState.selectedConversations.clear();
      yield this.inboxState.applyBulkMacroActions(ids, actions);
    } catch {
      this.notificationsService.notifyError(this.intl.t('inbox.bulk-edit.errors.edit'));
    }
  }

  /**
   * Event handlers
   */

  @action reload(opts: { reason: string }) {
    taskFor(this.conversationList.reload).perform(opts);
  }

  @action async handleConversationReadEvent(conversation: Conversation) {
    // We can get the conversation read event before the conversation list has
    // finished loading. If it's still loading, wait for it to finish before
    // trying to find the conversation.
    let fetchConversations = taskFor(this.conversationList.fetchConversations);
    if (fetchConversations.isRunning) {
      try {
        await fetchConversations.last;
      } catch (e) {
        if (!didCancel(e)) {
          throw e;
        }
      }
    }

    let summary = this.conversationList.conversations.findBy('id', conversation.id);
    summary?.markAsRead();
  }

  @action async handleConversationUnreadEvent(conversation: Conversation) {
    let summary = this.conversationList.conversations.findBy('id', conversation.id);
    summary?.markAsUnread();
  }

  @action
  private onResetConversationList() {
    // If the active conversation is in the list, we don't redirect.
    if (this.conversationList.conversations.findBy('id', this.inboxState.activeConversationId)) {
      return;
    }

    // If there are no conversations to redirect to, we don't redirect.
    let [conversation] = this.conversationList.conversations;
    if (!conversation) {
      return;
    }

    this.router.transitionTo(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      conversation.id,
    );
  }

  @action
  private async onConversationStateChanged(
    conversation: ConversationSummary,
    nextNavigableConversation: ConversationSummary | undefined,
  ): Promise<Transition | null> {
    if (conversation.id !== this.inboxState.activeConversationId) {
      return null;
    }

    await this.finQuestionAnswers.maybeShowModal(conversation.id);

    // intentionally not awaiting this, as we don't want to block the transition
    this.finQuestionAnswers.maybeExtractContentFromConversation(
      conversation.id,
      conversation.state,
    );

    if (
      !this.isMentionsInbox &&
      !isConversationStateEqual(
        this.selectedStateOption,
        conversation.state,
        conversation.ticketState,
      )
    ) {
      if (nextNavigableConversation) {
        return await this.router.transitionTo(this.conversationRoute, nextNavigableConversation);
      } else {
        return await this.router.transitionTo('inbox.workspace.inbox.inbox.index', {
          queryParams: { skipRedirection: true },
        });
      }
    }

    return null;
  }

  @action setCurrentItem(conversationId: number) {
    let activeItem = document.querySelector(
      `[data-conversation-id="${conversationId}"]`,
    ) as HTMLAnchorElement;

    this.currentItem = activeItem;
  }

  @action toggleSelection(
    options: {
      toggleAll?: boolean;
      conversation?: ConversationSummary;
    },
    event?: PointerEvent,
  ) {
    let { conversation, toggleAll = false } = options;

    if (toggleAll) {
      this.inboxState.selectedConversations.toggleAll(this.conversationsWithAccess);

      return;
    }

    if (conversation !== undefined) {
      this.setCurrentItem(conversation.id);
      this.inboxState.selectedConversations.toggle(
        conversation,
        this.conversationsWithAccess,
        event,
      );

      return;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::LeftNav::ConversationList': typeof ConversationListComponent;
    'inbox2/left-nav/conversation-list': typeof ConversationListComponent;
  }
}
